import { DefaultModal } from 'UI';
import { DefaultModalProps } from 'UI/Modals/DefaultModal/DefaultModal';
import { TermEnum } from 'UI/PriceLabel/PriceLabel';
import { CourseDto, CourseTypeEnum, ManualTypeEnum, SubscribeDtoPeriodEnum, SubscribeDtoTypeEnum } from 'api/generated';
import { UpgradeSubscriptionModal } from 'components';
import { useAsyncAction, useGetManual, useGoogleAnalytics, useNotifications } from 'hooks';
import { FC, useState } from 'react';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import { CourseOptionsForPayment } from 'store/ducks/courses/types';
import { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { ManualPaymentsDescriptions } from 'types';

import { LifetimeProducts, Products } from './components';

type ChooseProductModalProps = {
  course: CourseDto;
  openUnsubscribeModal: () => void;
} & DefaultModalProps;

const ChooseProductModal: FC<ChooseProductModalProps> = ({ course, openUnsubscribeModal, ...props }) => {
  const dispatch = useAppDispatch();
  const { errorToast, successToast } = useNotifications();
  const { getEventSubscriptionPaymentModalOpenedGA } = useGoogleAnalytics();
  const isCertificate = course.type === CourseTypeEnum.Certificate;

  const [isShowBonusModal, setIsShowBonusModal] = useState(false);
  const [setActiveCourseAction, isCourseActivating] = useAsyncAction(actions.courses.setActiveCourse);
  const [cancelSubscriptionDowngradeAction] = useAsyncAction(actions.courses.cancelSubscriptionDowngrade);
  const [cancelSubscriptionTrialDowngradeAction] = useAsyncAction(actions.courses.cancelSubscriptionTrialDowngrade);
  const [changeCourseSubscribeAction, isSubscriptionUpdating] = useAsyncAction(actions.courses.changeCourseSubscribe);

  const direction = useAppSelector(selectors.direction.selectDirection);
  const isLandingPage = useAppSelector(selectors.auth.selectIsLandingPage);

  const directionId = direction?.id || '';
  const mainColor = direction?.mainColorHex;

  window.addEventListener('popstate', function () {
    closeChooseProductModalOpen();
  });

  const { content } = useGetManual(ManualTypeEnum.PaymentDiscounts, course.id);

  const startUnsubscribeProcess = () => {
    closeChooseProductModalOpen();
    openUnsubscribeModal();
  };

  const toggleSubscription = (options: CourseOptionsForPayment, isCurrent: boolean) => {
    if (isCurrent) {
      if (isLandingPage) {
        dispatch(actions.auth.setIsLandingPage(null));
      }
      selectCourseOptionsForPayment(options);
      startUnsubscribeProcess();
    } else if (options.isDowngrade) {
      if (isLandingPage) {
        dispatch(actions.auth.setIsLandingPage(null));
      }
      downgradeSubscription(options.courseId, '', options.term as SubscribeDtoPeriodEnum, options.subscribeType);
    } else {
      // if (options.firstMonthDiscountId) {
      //   dispatch(actions.courses.setUseFirstMonthDiscount(!!options.firstMonthDiscountId));
      // }
      if (isLandingPage) {
        dispatch(actions.auth.setIsLandingPage(null));
      }
      closeChooseProductModalOpen();
      selectCourseOptionsForPayment(options);
      openPaymentModalOpen();
    }
  };

  const selectCourseOptionsForPayment = (options: CourseOptionsForPayment) => {
    dispatch(actions.courses.selectCourseOptionsForPayment(options));
  };

  const closeChooseProductModalOpen = () => {
    if (isLandingPage) {
      dispatch(actions.auth.setIsLandingPage(null));
    }
    dispatch(actions.courses.closeChooseProductModalOpen());
  };
  const openPaymentModalOpen = () => {
    getEventSubscriptionPaymentModalOpenedGA();
    dispatch(actions.courses.openPaymentModalOpen());
  };

  const setActiveCourse = async (courseId: string) => {
    try {
      await setActiveCourseAction({ courseId });
      closeChooseProductModalOpen();
    } catch {
      errorToast('Something went wrong');
    }
  };

  const downgradeSubscription = async (
    courseId: string,
    promocode: string,
    period: SubscribeDtoPeriodEnum,
    type: SubscribeDtoTypeEnum,
  ) => {
    try {
      const subscribeParams = {
        courseId,
        promocode,
        subscribeDto: {
          period,
          type,
        },
      };

      await changeCourseSubscribeAction(subscribeParams);

      closeChooseProductModalOpen();
      successToast('Subscription payment was successful');
    } catch {
      closeChooseProductModalOpen();
      errorToast('Something went wrong');
    }
  };

  const cancelSubscriptionDowngrade = async (courseId: string, isCancelTrial?: boolean) => {
    try {
      isCancelTrial
        ? await cancelSubscriptionTrialDowngradeAction({ courseId })
        : await cancelSubscriptionDowngradeAction({ courseId });
      closeChooseProductModalOpen();
    } catch {
      errorToast('Something went wrong');
    }
  };

  const { annualSubscriptions, monthlySubscriptions, purchase, installment } = course?.prices || {};

  return (
    <>
      <DefaultModal
        {...props}
        id={'chooseProduct'}
        title="Choose a product"
        containerCSS={ContainerCSS}
        headerContainerCSS={headerContainerCSS}
        modalCSS={ModalCSS}
        closeButtonCSS={CloseCSS}
        titleCSS={titleCSS}>
        {isCertificate ? (
          <LifetimeProducts
            toggleSubscription={toggleSubscription}
            cancelSubscriptionDowngrade={cancelSubscriptionDowngrade}
            currentPayment={course.currentPayment}
            nextPayment={course.nextPayment}
            setIsShowBonusModal={setIsShowBonusModal}
            isCertificate={isCertificate}
            mainColor={mainColor}
            // @ts-ignore
            directionId={directionId}
            // @ts-ignore
            installmentPrice={{ ...installment, term: TermEnum.LIFE_TIME }}
            // @ts-ignore
            purchasePrice={{ ...purchase, term: TermEnum.LIFE_TIME }}
            courseId={course.id}
            isTrialStarting={isCourseActivating}
            startTrial={setActiveCourse}
            startUnsubscribeProcess={startUnsubscribeProcess}
            isSubscriptionUpdating={isSubscriptionUpdating}
            paymentsDescriptions={content?.monthlySubscriptions as ManualPaymentsDescriptions}
            mostEffectiveLabel={content?.mostEffective}
          />
        ) : (
          <Products
            toggleSubscription={toggleSubscription}
            cancelSubscriptionDowngrade={cancelSubscriptionDowngrade}
            currentPayment={course.currentPayment}
            nextPayment={course.nextPayment}
            isCertificate={isCertificate}
            setIsShowBonusModal={setIsShowBonusModal}
            mainColor={mainColor}
            // @ts-ignore
            directionId={directionId}
            // @ts-ignore
            purchasePrice={{ ...purchase, term: TermEnum.LIFE_TIME }}
            // @ts-ignore
            annualPrices={{ ...annualSubscriptions, term: TermEnum.YEARLY }}
            // @ts-ignore
            monthlyPrices={{ ...monthlySubscriptions, term: TermEnum.MONTHLY }}
            courseId={course.id}
            isTrialStarting={isCourseActivating}
            startTrial={setActiveCourse}
            startUnsubscribeProcess={startUnsubscribeProcess}
            isSubscriptionUpdating={isSubscriptionUpdating}
            paymentsDescriptions={content?.monthlySubscriptions as ManualPaymentsDescriptions}
            mostEffectiveLabel={content?.mostEffective}
          />
        )}
      </DefaultModal>
      <UpgradeSubscriptionModal
        isOpen={isShowBonusModal}
        onClose={() => setIsShowBonusModal(false)}
        manualType={
          course.currentPayment?.level === 'gold'
            ? ManualTypeEnum.BonusSectionUpgrade
            : ManualTypeEnum.BonusSectionDefault
        }
        isBonusModal={true}
      />
    </>
  );
};

export default ChooseProductModal;

const ContainerCSS = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 45px 40px 57px;

  ${respondToWidth.sm`
    width:100%;
    padding-left: 25px;
    padding-right: 17px;
    padding-bottom: 32px;
  `}

  ${respondToWidth.s`
    height:100%;
    padding: 16px;
    padding-right: 8px;
    padding-bottom: 32px;
    border-radius: 18px;
  `}

  @media (max-height: 700px) {
    margin-top: 70px;
  }

  @media (max-height: 600px) {
    margin-top: 150px;
  }
`;

const ModalCSS = css`
  max-height: 800px;

  margin: auto;
  padding: 22px 0px;

  ${respondToWidth.sm`
    bottom: unset !important;
  `}
`;

const headerContainerCSS = css`
  margin-bottom: 24px;

  ${respondToWidth.s`
    justify-content: center;
  `}
`;

const titleCSS = css`
  color: ${({ theme: { colors } }) => colors.neutrals[1]};

  @media (max-width: 1180px) {
    margin-bottom: 105px;
  }

  ${respondToWidth.s`
    margin-top: 20px;
    text-align: center;
    margin-bottom: 90px;
  `};
`;

const CloseCSS = css`
  top: 20px;
  right: 22px;
`;

// const Container = styled.div`
//   width: 100%;
//   height: 100%;

//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;
