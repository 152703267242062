import React, { FC } from 'react';
import { selectors, useAppSelector } from 'store';
import { PaymentOptions } from 'store/ducks/courses/types';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { formatNumber, getReducedPrice } from 'utils';

interface PriceComponentProps {
  amount: number;
  isDefaultCoupon: boolean;
  discountValue: number;
  isFirstDiscount: boolean;
  isInstallmentTerm?: boolean;
  firstMonthDiscountAmount: number;
  isAmountFirstDiscountType: boolean;
}

const PriceComponent: FC<PriceComponentProps> = ({
  amount,
  discountValue,
  isDefaultCoupon,
  isInstallmentTerm,
  isFirstDiscount,
  firstMonthDiscountAmount,
  isAmountFirstDiscountType,
}) => {
  const paymentOption = useAppSelector(selectors.courses.selectPaymentOption);

  const formattedAmount = formatNumber(amount);

  const termText = paymentOption === PaymentOptions.ONETIME ? 'one-time' : 'Over 12 months';
  const termTextForSignUpModal = isInstallmentTerm ? 'Over 12 months' : 'one-time';

  const calculatePrice = ({
    isDefaultCoupon,
    amount,
    discountValue,
  }: {
    isDefaultCoupon: boolean;
    amount?: number;
    discountValue: number;
  }) => {
    const discountApplicable = amount && discountValue && !isDefaultCoupon;

    if (discountApplicable) {
      const discountedAmount = amount - discountValue;
      if (isFirstDiscount) {
        return getReducedPrice(discountedAmount - firstMonthDiscountAmount);
      } else {
        return discountedAmount >= 1 ? getReducedPrice(discountedAmount) : getReducedPrice(amount);
      }
    }

    if (isDefaultCoupon && amount) {
      const discountedAmount = amount - discountValue;
      if (isFirstDiscount) {
        return getReducedPrice(discountedAmount - firstMonthDiscountAmount);
      } else {
        return discountedAmount >= 1 ? getReducedPrice(discountedAmount) : getReducedPrice(amount);
      }
    }

    if (isFirstDiscount && firstMonthDiscountAmount && amount) {
      if (isAmountFirstDiscountType) {
        return amount - firstMonthDiscountAmount;
      } else {
        return getReducedPrice(amount - ((amount / 12) * firstMonthDiscountAmount) / 100, 0);
      }
    }

    return amount || 0;
  };

  const calculatedPrice = calculatePrice({ isDefaultCoupon, discountValue, amount });
  const formattedCalculatedPrice = formatNumber(calculatedPrice);

  return (
    <PriceContainer>
      <PriceLabel>
        {discountValue ? (
          <OldPriceContainer>
            <OldPrice>{`$${formattedAmount}`}</OldPrice>
          </OldPriceContainer>
        ) : null}
        <CurrentPrice>{`$${formattedCalculatedPrice}`}</CurrentPrice>
      </PriceLabel>
      <CurrentPriceContainer>
        <CouponText>{isInstallmentTerm ? termTextForSignUpModal : termText}</CouponText>
      </CurrentPriceContainer>
    </PriceContainer>
  );
};

export default PriceComponent;

const PriceLabel = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 35px;

  ${respondToWidth.s`
  margin-bottom: 20px;
`}
`;

const PriceContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
`;

const CurrentPriceContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CurrentPrice = styled.span`
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[12]};
    ${typography.title_1_bold_42}
  `};
`;

const OldPriceContainer = styled.div`
  ${({ theme: { colors } }) => css`
    background-color: ${colors.neutrals[11]};
    border-radius: 12px;
    padding: 0 10px 0 10px;
    align-items: center;
    display: flex;
    margin-right: 15px;
  `};
`;

const OldPrice = styled.span`
  ${({ theme: { colors, typography } }) => css`
    width: fit-content;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    text-decoration: line-through;
    color: ${colors.neutrals[1]};
    ${typography.title_3_bold_28}

    ::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      transform: translateY(1px);
      background-color: ${colors.neutrals[11]};
    }
  `}
`;

const CouponText = styled.span`
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 35px;
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[11]};
    ${typography.body_basic_semibold_14}
  `};
  font-size: 16px;

  ${respondToWidth.s`
  margin-bottom: 20px;
`}
`;
