import { StyledText } from 'UI';
import { SuccessIconWhite, Tag } from 'assets/icons';
import React, { FC } from 'react';
import { UseFormReset } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';
import { formatNumber, getReducedPrice } from 'utils';

interface SuccessCouponProps {
  subscribeType: string;
  courseName: string;
  oldAmount?: number;
  amount: number;
  promoCode?: string;
  discountAmount: number;
  discountValue: number;
  removeCoupon: () => void;
  reset: UseFormReset<any>;
  setIsEnterCoupon: (value: boolean) => void;
}

const SuccessCoupon: FC<SuccessCouponProps> = ({
  subscribeType,
  courseName,
  amount,
  promoCode,
  discountValue,
  removeCoupon,
  reset,
  setIsEnterCoupon,
}) => {
  const totalAmount = getReducedPrice(amount - discountValue);
  const formattedAmount = formatNumber(amount);

  return (
    <div>
      <SuccessCouponContainer>
        <CouponWrapper>
          <TextCoupon>{`${courseName} ${subscribeType} `}</TextCoupon>
          <TextCoupon2>{`$${formattedAmount}`}</TextCoupon2>
        </CouponWrapper>

        <CouponWrapper>
          <PercentContainer>
            <IconWrapper>
              <Tag width={20} height={20} />
            </IconWrapper>
            <TextCoupon>{`${promoCode}`}</TextCoupon>
          </PercentContainer>

          <TextCoupon2>{`-$${discountValue}`}</TextCoupon2>
        </CouponWrapper>
        <CouponWrapper>
          <TextCouponTitleTotal>{'Total:'}</TextCouponTitleTotal>
          <TextCouponTotal>{`USD $${totalAmount}`}</TextCouponTotal>
        </CouponWrapper>
      </SuccessCouponContainer>
      <SuccessMessageContainer>
        <IconWrapper>
          <SuccessIconWhite width={15} height={15} />
        </IconWrapper>
        <SuccessMessage>Coupon applied</SuccessMessage>
        <RemoveCouponButton
          onClick={() => {
            removeCoupon();
            setIsEnterCoupon(false);
            reset({
              coupon: '',
            });
          }}>
          Remove Coupon
        </RemoveCouponButton>
      </SuccessMessageContainer>
    </div>
  );
};

export default SuccessCoupon;

const SuccessCouponContainer = styled.div`
  display: grid;
  gap: 7px;
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 12px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
`;

const SuccessMessageContainer = styled.div`
  display: flex;
  margin-bottom: 35px;
`;

const TextCoupon = styled.span`
  ${({ theme: { typography } }) => css`
    ${typography.body_basic_medium_14}
  `};
`;

const TextCouponTitleTotal = styled.span`
  ${({ theme: { typography } }) => css`
    ${typography.body_basic_bold_14}
  `};
`;

const TextCoupon2 = styled.span`
  margin-left: auto;
  ${({ theme: { typography } }) => css`
    ${typography.body_basic_medium_14}
  `};
`;

const TextCouponTotal = styled.span`
  margin-left: auto;
  ${({ theme: { typography } }) => css`
    ${typography.body_basic_bold_14}
  `};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const PercentContainer = styled.div`
  display: flex;
`;

const CouponWrapper = styled.div`
  display: flex;
  gap: 40px;
`;

const SuccessMessage = styled(StyledText)`
  text-align: start;
  margin-right: 35px;

  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[11]};
    ${typography.body_basic_medium_14}

    ${respondToWidth.s`
      ${typography.footnote_semibold_12}
  `}
  `};
`;

const RemoveCouponButton = styled.button`
  text-decoration: underline;
  text-underline-offset: 4px;
  color: ${({ theme: { colors } }) => colors.neutrals[11]};
`;
