import SubscriptionItemLifeTime from 'UI/SubscriptionItemLifeTime';
import { PaymentDtoTypeEnum, SubscribeDtoTypeEnum } from 'api/generated';
import arrowDow2 from 'assets/images/arrow-down2.png';
import arrowDown from 'assets/images/arrow-down.png';
import shape from 'assets/images/shape.png';
import {
  installmentBronzeCapabilities,
  installmentBronzeCapabilitiesResponsive,
  installmentCommonCapabilities,
  installmentGoldCapabilities,
  installmentGoldCapabilitiesResponsive,
  installmentSilverCapabilities,
  installmentSilverCapabilitiesResponsive,
} from 'constant';
import React, { FC, useEffect, useState } from 'react';
import { selectors, useAppSelector } from 'store';
import { PaymentOptions } from 'store/ducks/courses/types';
import styled, { css } from 'styled-components';
import { getReducedPrice } from 'utils';

import { PaymentOptionsSelector, TrialText } from './components';
import { BonusSection } from './components/BonusSection/BonusSection';
import {
  bronzeAnimatedMapping,
  bronzeDarkAnimatedMapping,
  goldAnimatedMapping,
  goldDarkAnimatedMapping,
  silverAnimatedMapping,
  silverDarkAnimatedMapping,
} from './constants';
import { ActivePlan, PlanLifeTime, ProductsProps } from './types';

const LifetimeProducts: FC<ProductsProps> = ({
  installmentPrice,
  purchasePrice,
  currentPayment,
  mainColor,
  directionId,
  nextPayment,
  // isCertificate,
  setIsShowBonusModal,
  courseId,
  isTrialStarting,
  toggleSubscription,
  startUnsubscribeProcess,
  cancelSubscriptionDowngrade,
  startTrial,
  mostEffectiveLabel,
}) => {
  const isTrialText = currentPayment?.level === 'none' || nextPayment?.type === 'Free';
  const [activeIndex, setActiveIndex] = useState(0);
  const [activePeriod, setActivePeriod] = useState('gold');
  const [planType, setPlanType] = useState<ActivePlan>();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);
  const paymentOption = useAppSelector(selectors.courses.selectPaymentOption);
  const isPaymentPlan = paymentOption === PaymentOptions.PAYMENT_PLAN;

  const isGoldPurchase = currentPayment?.type === PaymentDtoTypeEnum.Purchase;

  const isResponsive = screenWidth <= 1180;

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!directionId) return <></>;

  const plansLifeTime: Array<PlanLifeTime> = [
    {
      installmentPrice: installmentPrice.gold,
      purchasePrice: purchasePrice?.gold,
      type: SubscribeDtoTypeEnum.Gold,
      capabilities: installmentGoldCapabilities,
      capabilitiesResponsive: installmentGoldCapabilitiesResponsive,
      animationData: goldAnimatedMapping[directionId],
      darkAnimationData: goldDarkAnimatedMapping[directionId],
    },

    {
      installmentPrice: installmentPrice.silver,
      purchasePrice: purchasePrice?.silver,
      type: SubscribeDtoTypeEnum.Silver,
      capabilities: installmentSilverCapabilities,
      capabilitiesResponsive: installmentSilverCapabilitiesResponsive,
      animationData: silverAnimatedMapping[directionId],
      darkAnimationData: silverDarkAnimatedMapping[directionId],
    },
    {
      installmentPrice: installmentPrice.bronze,
      purchasePrice: purchasePrice?.bronze,
      type: SubscribeDtoTypeEnum.Bronze,
      capabilities: installmentBronzeCapabilities,
      capabilitiesResponsive: installmentBronzeCapabilitiesResponsive,
      animationData: bronzeAnimatedMapping[directionId],
      darkAnimationData: bronzeDarkAnimatedMapping[directionId],
    },
  ];

  const plansInstallment: Array<PlanLifeTime> = [
    {
      installmentPrice: installmentPrice.gold,
      purchasePrice: purchasePrice?.gold,
      type: SubscribeDtoTypeEnum.Gold,
      capabilities: installmentGoldCapabilities,
      capabilitiesResponsive: installmentGoldCapabilitiesResponsive,
      animationData: goldAnimatedMapping[directionId],
      darkAnimationData: goldDarkAnimatedMapping[directionId],
    },
  ];
  let countPlans: number;

  const calculateCurrentPrice = (
    amount: number | undefined,
    defaultCouponDiscount?: number,
    defaultCouponDiscountType?: string,
  ) => {
    if (!amount) return;

    if (defaultCouponDiscount && amount - defaultCouponDiscount >= 1 && defaultCouponDiscountType === 'amount') {
      return getReducedPrice(amount - defaultCouponDiscount);
    }

    if (defaultCouponDiscount && defaultCouponDiscountType === 'percentage') {
      const discountAmount = amount * (defaultCouponDiscount / 100);

      if (amount - discountAmount >= 1) {
        return getReducedPrice(amount - discountAmount);
      }
    }

    return getReducedPrice(amount);
  };

  return (
    <Root style={{ marginTop: isResponsive ? '-557px' : '15px' }}>
      <Wrapper>
        <Container $isShowPurchase={false} isResponsive={isResponsive}>
          {!isResponsive && (
            <ImageContainer>
              <img src={shape} alt="shape" style={{ filter: isDarkMode ? 'invert(1)' : 'unset' }} />
            </ImageContainer>
          )}

          <ImageDivArrowRight>
            <img src={arrowDow2} alt="arrow" style={{ filter: isDarkMode ? 'invert(1)' : 'unset' }} />
          </ImageDivArrowRight>

          <ImageDivArrowLeft>
            <img src={arrowDown} alt="arrow" style={{ filter: isDarkMode ? 'invert(1)' : 'unset' }} />
          </ImageDivArrowLeft>

          {currentPayment?.level === 'none' && (
            <BonusSection isProduct={true} isCertificate={true} setIsShowBonusModal={setIsShowBonusModal} />
          )}

          {plansLifeTime && (
            <PlansContainer>
              {!isResponsive && (
                <PaymentOptionsSelector
                  mainColor={mainColor}
                  goldPurchasePrice={calculateCurrentPrice(
                    purchasePrice?.gold?.amount,
                    purchasePrice?.gold?.defaultCoupon?.discountAmount,
                    purchasePrice?.gold?.defaultCoupon?.discountType,
                  )}
                  goldInstallmentPrice={calculateCurrentPrice(
                    installmentPrice?.gold?.amount,
                    installmentPrice?.gold?.defaultCoupon?.discountAmount,
                    installmentPrice?.gold?.defaultCoupon?.discountType,
                  )}
                  subscribeType={SubscribeDtoTypeEnum.Gold}
                />
              )}
              {!isResponsive &&
                installmentCommonCapabilities.map((cap, index) => (
                  <div style={{ alignItems: 'center', width: '230px' }} key={index}>
                    <CapabilitiesContainer>{cap.title}</CapabilitiesContainer>
                  </div>
                ))}
            </PlansContainer>
          )}

          {(isPaymentPlan ? plansInstallment : plansLifeTime).map((plan, index) => {
            return (
              <SubscriptionItemLifeTime
                key={index}
                isResponsive={isResponsive}
                // @ts-ignore
                plan={plan}
                setIsShowBonusModal={setIsShowBonusModal}
                indexPlan={index}
                animationData={isDarkMode ? plan?.darkAnimationData : plan?.animationData}
                setPlanType={setPlanType}
                planType={planType}
                countPlans={countPlans}
                mainColor={mainColor}
                isShow={activeIndex === index}
                activePeriod={activePeriod}
                setActivePeriod={setActivePeriod}
                setActiveIndex={setActiveIndex}
                toggleSubscription={toggleSubscription}
                courseId={courseId}
                isCurrentSubscription={currentPayment?.level === plan?.type}
                currentPayment={currentPayment}
                nextPayment={nextPayment?.level === plan?.type ? nextPayment : undefined}
                isShowButton={!isGoldPurchase}
                goldPurchasePrice={calculateCurrentPrice(
                  purchasePrice?.gold?.amount,
                  purchasePrice?.gold?.defaultCoupon?.discountAmount,
                  purchasePrice?.gold?.defaultCoupon?.discountType,
                )}
                goldInstallmentPrice={calculateCurrentPrice(
                  installmentPrice?.gold?.amount,
                  installmentPrice?.gold?.defaultCoupon?.discountAmount,
                  installmentPrice?.gold?.defaultCoupon?.discountType,
                )}
                mostEffectiveLabel={mostEffectiveLabel}
              />
            );
          })}
        </Container>
        {isTrialText && (
          <TrialText
            isDowngrade={nextPayment?.type === 'Free'}
            cancelSubscriptionDowngrade={() => cancelSubscriptionDowngrade(courseId, true)}
            currentPayment={currentPayment}
            startTrial={() => startTrial(courseId)}
            isTrialStarting={isTrialStarting}
            startUnsubscribeProcess={startUnsubscribeProcess}
          />
        )}
      </Wrapper>
    </Root>
  );
};

export default LifetimeProducts;

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  grid-gap: 32px;

  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const PlansContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 522px;
`;

const ImageContainer = styled.div`
  width: 70px;
  height: 70px;
  position: absolute;
  top: 260px;
  left: 100px;
`;

const ImageDivArrowRight = styled.div`
  width: 70px;
  height: 70px;
  position: absolute;
  top: 40px;
  right: 75px;
  z-index: 1;

  @media (max-width: 1180px) {
    top: 190px;
    right: 30px;
  }
`;

const ImageDivArrowLeft = styled.div`
  width: 70px;
  height: 70px;
  position: absolute;
  top: 95px;
  left: 185px;
  z-index: 1;

  @media (max-width: 1180px) {
    top: 140px;
    left: 90px;
  }

  @media (max-width: 570px) {
    top: 130px;
    left: 30px;
  }
`;

const CapabilitiesContainer = styled.div`
  ${({ theme: { typography } }) => typography.body_large_semibold_16}
  margin-bottom: 21px;
`;

const Container = styled.div<{ $isShowPurchase: boolean; isResponsive: boolean }>`
  display: ${({ isResponsive }) => (isResponsive ? 'unset' : 'flex')};
  height: max-content;
  gap: 20px;

  ${({ $isShowPurchase }) =>
    $isShowPurchase
      ? css`
          margin: 0 auto auto;
          display: grid;
          grid-template-columns: 352px;
        `
      : css``}

  @media (max-width: 1180px) {
    width: 500px;
  }

  @media (max-width: 570px) {
    width: 370px;
  }

  @media (max-width: 370px) {
    width: 320px;
  }
`;
