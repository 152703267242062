import { useAsyncAction } from 'hooks';
import { useGoogleAnalytics } from 'hooks';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { actions, selectors, useAppSelector } from 'store';
import styled, { css } from 'styled-components';

import { Button } from '../../../UI';
import DefaultModal from '../../../UI/Modals/DefaultModal';
import { CourseShortInfoResponseDto } from '../../../api/generated';
import { SuccessIcon } from '../../../assets/icons';
import { useMultiStepForm } from '../../../hooks/useMultiStepForm';
import SelectCourseForm from './components/steps/SelectCourseForm/SelectCourseForm';
import SendEmailForm from './components/steps/SendEmailForm/SendEmailForm';
import SuccessSendForm from './components/steps/SuccessSendForm/SuccessSendForm';

const MvpModal = ({ onClose }: any) => {
  const { getTrialCertificationSelectedGA, trialRequestedGA } = useGoogleAnalytics();
  const [selectedAnswer, setSelectedAnswer] = useState<CourseShortInfoResponseDto>({ id: '', name: '' });
  const [courseName, setCourseName] = useState('');
  const handleSelectedAnswerChange = (selectedAnswer: CourseShortInfoResponseDto) => {
    setSelectedAnswer(selectedAnswer);
  };

  const [isError, setIsError] = useState<string>('');

  const [signUpByEmailAction, isSignUpFetching] = useAsyncAction(actions.auth.signUpByEmail);
  const [setActiveCourseAction, isSetActiveCourseFetching] = useAsyncAction(actions.courses.setActiveCourse);
  const [subscribeActiveCampaignAction, isSetSubscribeActiveCampaignAction] = useAsyncAction(
    actions.activeCampaign.subscribeActiveCampaign,
  );

  const direction = useAppSelector(selectors.direction.selectDirection);

  const directionId = direction?.id || '';

  const signUpByEmail = async (email: string) => {
    if (directionId) {
      await signUpByEmailAction({ email, directionId, courseId: selectedAnswer?.id });
      const res = await setActiveCourseAction({ courseId: selectedAnswer?.id });
      setCourseName(res.name);
      await subscribeActiveCampaignAction({ courseId: selectedAnswer?.id, email });
      getTrialCertificationSelectedGA(selectedAnswer?.name);
      trialRequestedGA();
      next();
    }
  };

  const isLoading = isSignUpFetching || isSetActiveCourseFetching || isSetSubscribeActiveCampaignAction;

  const steps: React.ReactElement[] = [
    <SelectCourseForm key={'SelectCourseForm'} onSelectedAnswerChange={handleSelectedAnswerChange} />,
    <SendEmailForm key={'SendEmailForm'} isLoading={isLoading} isError={isError} />,
    <SuccessSendForm key={''} courseName={courseName} />,
  ];
  const stepTitles = ['Which Certification Are You Studying?', 'That’s a great Certification!'];

  const methods = useForm();

  const { currentStepIndex, next, back, step: CurrentStepComponent, isLastStep, isFirstStep } = useMultiStepForm(steps);

  const onSubmit = async () => {
    try {
      const emailFieldValue = methods.getValues('email');
      await signUpByEmail(emailFieldValue);
    } catch (error: any) {
      console.log(error);
      if (error.statusCode === 400) {
        setIsError('Please, enter your e-mail in the example@email.com format.');

        return;
      }
      if (error.message) {
        setIsError(error.message);
        console.error(error.message);
      } else {
        setIsError('An error occurred');
        console.error(error);
      }
    }
  };

  return (
    <DefaultModal
      title={stepTitles[currentStepIndex] ?? false}
      titleCSS={modalTitleCss}
      isOpen={true}
      showBackButton={isFirstStep || isLastStep ? false : true}
      onBackIconClick={back}
      modalCSS={modalCss}
      onRequestClose={onClose}
      containerCSS={modalContainerCss}>
      <SuccessIconWrapper>{isLastStep && <SuccessIcon />}</SuccessIconWrapper>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          {CurrentStepComponent}
          {currentStepIndex === 0 ? (
            <Button onClick={next} disabled={!selectedAnswer}>
              Next
            </Button>
          ) : (
            false
          )}
        </Form>
      </FormProvider>
    </DefaultModal>
  );
};

export default MvpModal;

const Form = styled.form``;
const modalCss = css`
  max-width: 680px;
  width: 100%;
`;
const modalContainerCss = css`
  padding: 40px 40px 60px 40px;
`;
const modalTitleCss = css`
  font-size: 28px;
  line-height: 36px; /* 128.571% */
  letter-spacing: -0.28px;
`;
const SuccessIconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
