import { TermEnum } from 'UI/PriceLabel/PriceLabel';
import { PaymentDtoTypeEnum, SubscribeDtoTypeEnum } from 'api/generated';

export { COLORS } from './colors';
export { pluralForms } from './pluralForms';
export { accessLevels } from './accessLevels';
export { dateFormats } from './dateFormats';

export const levelMapping = {
  [SubscribeDtoTypeEnum.None]: 'Trial',
  [SubscribeDtoTypeEnum.Bronze]: 'Rookie',
  [SubscribeDtoTypeEnum.Silver]: 'All-Star',
  [SubscribeDtoTypeEnum.Gold]: 'MVP',
};

export const levelGradeMapping = {
  [SubscribeDtoTypeEnum.None]: 0,
  [SubscribeDtoTypeEnum.Bronze]: 1,
  [SubscribeDtoTypeEnum.Silver]: 2,
  [SubscribeDtoTypeEnum.Gold]: 3,
};

export const paymentTypeMapping = {
  [PaymentDtoTypeEnum.Free]: 'version',
  [PaymentDtoTypeEnum.Subscription]: 'subscription',
  [PaymentDtoTypeEnum.Purchase]: 'purchase',
  [PaymentDtoTypeEnum.Installment]: 'Installment',
  [PaymentDtoTypeEnum.None]: 'none',
};

export const newSubscribeTypes = {
  none: 'None',
  bronze: 'Rookie',
  silver: 'All-Star',
  gold: 'MVP',
};

export const termMapping = {
  [TermEnum.MONTHLY]: 'Monthly',
  [TermEnum.YEARLY]: 'Annual',
  [TermEnum.LIFE_TIME]: 'Lifetime',
};

export const newTermMapping = {
  [TermEnum.MONTHLY]: 'per month',
  [TermEnum.YEARLY]: 'per year',
  [TermEnum.LIFE_TIME]: 'one time',
};

export type SubscribeType = 'bronze' | 'silver' | 'gold';

export enum PaymentsSystemsEnums {
  AMEX = 'amex',
  JCB = 'jcb',
  DISCOVER = 'discover',
  MASTERCARD = 'mastercard',
  VISA = 'visa',
  UNIONPAY = 'unionpay',
  DINERS = 'diners',
  CARTES_BANCAIRES = 'cartes_bancaires',
}

export const bronzeCapabilities = [
  { include: true },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
];

export const silverCapabilities = [
  { include: true },
  { include: true },
  { include: true },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
];

export const goldCapabilities = [
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
];

export const installmentBronzeCapabilities = [
  { include: true },
  { include: true },
  { include: true },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
];

export const installmentSilverCapabilities = [
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
  { include: false },
];

export const installmentGoldCapabilities = [
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
  { include: true },
];

export const bronzeCapabilitiesResponsive = [
  { title: 'Interactive Study Guide', include: true },
  { title: 'Exam Simulator', include: false },
  { title: 'Flashcard System', include: false },
  { title: 'Exam Pass Guarantee', include: false },
  { title: 'SurePass AI', include: false },
  { title: 'Audio Study Guide', include: false },
  { title: 'Performance Reporting', include: false },
  { title: 'Motivating Study Streaks', include: false },
  { title: 'Study Gamification', include: false },
  { title: 'Weak Point Training', include: false },
  { title: 'Tailored Study Plans', include: false },
  { title: 'Magic Mnemonics', include: false },
  { title: 'Exam Date Tracker', include: false },
];

export const silverCapabilitiesResponsive = [
  { title: 'Interactive Study Guide', include: true },
  { title: 'Exam Simulator', include: true },
  { title: 'Flashcard System', include: true },
  { title: 'Exam Pass Guarantee', include: false },
  { title: 'SurePass AI', include: false },
  { title: 'Audio Study Guide', include: false },
  { title: 'Performance Reporting', include: false },
  { title: 'Motivating Study Streaks', include: false },
  { title: 'Study Gamification', include: false },
  { title: 'Weak Point Training', include: false },
  { title: 'Tailored Study Plans', include: false },
  { title: 'Magic Mnemonics', include: false },
  { title: 'Exam Date Tracker', include: false },
];

export const goldCapabilitiesResponsive = [
  { title: 'Interactive Study Guide', include: true },
  { title: 'Exam Simulator', include: true },
  { title: 'Flashcard System', include: true },
  { title: 'Exam Pass Guarantee', include: true },
  { title: 'SurePass AI', include: true },
  { title: 'Audio Study Guide', include: true },
  { title: 'Performance Reporting', include: true },
  { title: 'Motivating Study Streaks', include: true },
  { title: 'Study Gamification', include: true },
  { title: 'Weak Point Training', include: true },
  { title: 'Tailored Study Plans', include: true },
  { title: 'Magic Mnemonics', include: true },
  { title: 'Exam Date Tracker', include: true },
];

export const installmentBronzeCapabilitiesResponsive = [
  { title: 'NCCA Accredited Cert', include: true },
  { title: 'Free Digital Textbook', include: true },
  { title: 'Interactive Study Guide', include: true },
  { title: 'Exam Simulator', include: false },
  { title: 'Flashcard System', include: false },
  { title: 'First Recert Is Free', include: false },
  { title: 'Job Assurance Guarantee', include: false },
  { title: 'Unlimited Exam Retakes', include: false },
  { title: 'SurePass AI', include: false },
  { title: 'Audio Study Guide', include: false },
  { title: 'Performance Reporting', include: false },
  { title: 'Motivating Studies Streaks', include: false },
  { title: 'Study Gamification', include: false },
  { title: 'Weak Point Training', include: false },
  { title: 'Tailored Study Plans', include: false },
  { title: 'Magic Mnemonics', include: false },
  { title: 'Essential Cheat Sheet', include: false },
  { title: 'Exam Date Tracker', include: false },
  { title: '0% interest financing', include: false },
];

export const installmentSilverCapabilitiesResponsive = [
  { title: 'NCCA Accredited Cert', include: true },
  { title: 'Free Digital Textbook', include: true },
  { title: 'Interactive Study Guide', include: true },
  { title: 'Exam Simulator', include: true },
  { title: 'Flashcard System', include: true },
  { title: 'First Recert Is Free', include: true },
  { title: 'Job Assurance Guarantee', include: true },
  { title: 'Unlimited Exam Retakes', include: false },
  { title: 'SurePass AI', include: false },
  { title: 'Audio Study Guide', include: false },
  { title: 'Performance Reporting', include: false },
  { title: 'Motivating Studies Streaks', include: false },
  { title: 'Study Gamification', include: false },
  { title: 'Weak Point Training', include: false },
  { title: 'Tailored Study Plans', include: false },
  { title: 'Magic Mnemonics', include: false },
  { title: 'Essential Cheat Sheet', include: false },
  { title: 'Exam Date Tracker', include: false },
  { title: '0% interest financing', include: false },
];

export const installmentGoldCapabilitiesResponsive = [
  { title: 'NCCA Accredited Cert', include: true },
  { title: 'Free Digital Textbook', include: true },
  { title: 'Interactive Study Guide', include: true },
  { title: 'Exam Simulator', include: true },
  { title: 'Flashcard System', include: true },
  { title: 'First Recert Is Free', include: true },
  { title: 'Job Assurance Guarantee', include: true },
  { title: 'Unlimited Exam Retakes', include: true },
  { title: 'SurePass AI', include: true },
  { title: 'Audio Study Guide', include: true },
  { title: 'Performance Reporting', include: true },
  { title: 'Motivating Studies Streaks', include: true },
  { title: 'Study Gamification', include: true },
  { title: 'Weak Point Training', include: true },
  { title: 'Tailored Study Plans', include: true },
  { title: 'Magic Mnemonics', include: true },
  { title: 'Essential Cheat Sheet', include: true },
  { title: 'Exam Date Tracker', include: true },
  { title: '0% interest financing', include: true },
];

export const commonCapabilities = [
  { title: 'Interactive Study Guide' },
  { title: 'Exam Simulator' },
  { title: 'Flashcard System' },
  { title: 'Exam Pass Guarantee' },
  { title: 'SurePass AI' },
  { title: 'Audio Study Guide' },
  { title: 'Performance Reporting' },
  { title: 'Motivating Study Streaks' },
  { title: 'Study Gamification' },
  { title: 'Weak Point Training' },
  { title: 'Tailored Study Plans' },
  { title: 'Magic Mnemonics' },
  { title: 'Exam Date Tracker' },
];

export const installmentCommonCapabilities = [
  { title: 'NCCA Accredited Cert' },
  { title: 'Free Digital Textbook' },
  { title: 'Interactive Study Guide' },
  { title: 'Exam Simulator' },
  { title: 'Flashcard System' },
  { title: 'First Recert Is Free' },
  { title: 'Job Assurance Guarantee' },
  { title: 'Unlimited Exam Retakes' },
  { title: 'SurePass AI' },
  { title: 'Audio Study Guide' },
  { title: 'Performance Reporting' },
  { title: 'Motivating Studies Streaks' },
  { title: 'Study Gamification' },
  { title: 'Weak Point Training' },
  { title: 'Tailored Study Plans' },
  { title: 'Magic Mnemonics' },
  { title: 'Essential Cheat Sheet' },
  { title: 'Exam Date Tracker' },
  { title: '0% interest financing' },
];

export const tabs = [
  {
    title: 'MVP',
    id: 'gold',
  },
  {
    title: 'All-Star',
    id: 'silver',
  },
  {
    title: 'Rookie',
    id: 'bronze',
  },
];

export const monthsAdapter: Record<number, string> = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};

export const monthsAdapterRevert: Record<string, number> = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};
