import { Checkbox } from 'UI';
import { CheckboxVariant } from 'UI/Checkbox/Checkbox';
import { CouponDiscountTypeEnum } from 'api/generated';
import { GreenTag } from 'assets/icons';
import { useAsyncAction } from 'hooks';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { UseFormReset } from 'react-hook-form';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { formatNumber, getReducedPrice } from 'utils';

export const SubscribeDtoTypeEnum = {
  Bronze: 'bronze',
  Silver: 'silver',
  Gold: 'gold',
} as const;

export type SubscribeDtoType = typeof SubscribeDtoTypeEnum[keyof typeof SubscribeDtoTypeEnum];

interface SuccessCouponProps {
  subscribeType: SubscribeDtoType;
  courseName: string;
  oldAmount?: number;
  amount: number;
  promoCode?: string;
  setClientSecret: Dispatch<SetStateAction<string | undefined>> | undefined;
  setInvoiceId: Dispatch<SetStateAction<string>>;
  setIsLoadingChangeFirstCoupon: Dispatch<SetStateAction<boolean>>;
  discountAmount: number;
  isLoadingPromocode: boolean;
  invoiceId: string;
  setCertificateFinalPrice: Dispatch<SetStateAction<number | false>>;
  discountValue: number;
  removeCoupon: () => void;
  reset: UseFormReset<any>;
}

const SuccessCoupon: FC<SuccessCouponProps> = ({
  amount,
  discountValue,
  isLoadingPromocode,
  promoCode,
  invoiceId,
  subscribeType,
  setCertificateFinalPrice,
  setClientSecret,
  setInvoiceId,
  setIsLoadingChangeFirstCoupon,
}) => {
  //@ts-ignore
  const [changeUseFirstCoupon] = useAsyncAction(actions.payments.changeUseFirstPromoCode);
  const dispatch = useAppDispatch();
  const direction = useAppSelector(selectors.direction.selectDirection);
  const activeCourse = useAppSelector(selectors.courses.selectActiveCourse);
  const selectedCourse = useAppSelector(selectors.courses.selectCourse);
  const signUpModalCourse = useAppSelector(selectors.courses.selectPublicCourse);
  const course = signUpModalCourse || selectedCourse || activeCourse;
  const formattedAmount = formatNumber(amount);

  // @ts-ignore
  const isFirstDiscount = !!course?.prices?.installment?.[subscribeType]?.firstMonthDiscountCouponId;

  const firstMonthDiscountAmount = Number(
    // @ts-ignore
    course?.prices?.installment?.[subscribeType].firstMonthDiscountCoupon?.discountAmount,
  );

  const firstMonthDiscountType =
    // @ts-ignore
    course?.prices?.installment?.[subscribeType]?.firstMonthDiscountCoupon?.discountType;

  const firstMonthDiscountPromoCode =
    // @ts-ignore
    course?.prices?.installment?.[subscribeType].firstMonthDiscountCoupon?.promoCode;

  const isAmountFirstDiscountType = firstMonthDiscountType === CouponDiscountTypeEnum.Amount;

  const totalAmount = getReducedPrice(amount - discountValue, 0);
  const perMonth = getReducedPrice(totalAmount / 12, 1);

  const totalAmountWithFirstMonthDiscount = formatNumber(
    isAmountFirstDiscountType
      ? getReducedPrice(amount - discountValue - firstMonthDiscountAmount, 0)
      : getReducedPrice(amount - discountValue - (perMonth * firstMonthDiscountAmount) / 100, 0),
  );

  const [isFirstMonthDiscount, setIsFirstMonthDiscount] = useState<boolean>(false);

  const getPriceWithFirthMonthDiscount = () => {
    if (firstMonthDiscountType === CouponDiscountTypeEnum.Amount) {
      return getReducedPrice(perMonth - firstMonthDiscountAmount, 1);
    } else {
      const discount = (perMonth * firstMonthDiscountAmount) / 100;
      return getReducedPrice(perMonth - discount, 1);
    }
  };

  const initialAmount = isFirstMonthDiscount ? getPriceWithFirthMonthDiscount() : perMonth;

  setCertificateFinalPrice(initialAmount);

  const checkboxLabelForDiscountAmount = `$${perMonth - firstMonthDiscountAmount}
   Down, Start Today!`;

  const checkboxLabelForDiscountPercentage = `$${getReducedPrice(
    perMonth - (perMonth * firstMonthDiscountAmount) / 100,
  )}
   Down, Start Today!`;

  const discountCheckboxLabel = isAmountFirstDiscountType
    ? checkboxLabelForDiscountAmount
    : checkboxLabelForDiscountPercentage;

  const toggleIsFirstMonthDiscount = async () => {
    if (!setClientSecret || !direction) return;
    setIsLoadingChangeFirstCoupon(true);
    dispatch(actions.courses.setUseFirstMonthDiscount(!isFirstMonthDiscount));
    setIsFirstMonthDiscount(!isFirstMonthDiscount);

    const { clientSecret, invoiceId: newInvoiceId } = await changeUseFirstCoupon({
      promocode: promoCode,
      invoiceId,
      useFirstMonthDiscount: !isFirstMonthDiscount,
      directionId: direction?.id,
    });
    setClientSecret(clientSecret);
    setInvoiceId(newInvoiceId);
    setIsLoadingChangeFirstCoupon(false);
  };

  return (
    <div>
      <SuccessCouponContainer>
        <CouponWrapper>
          <TextCoupon>Product Total:</TextCoupon>
          <TextCoupon2>{`$${formattedAmount}`}</TextCoupon2>
        </CouponWrapper>

        {promoCode && (
          <DiscountsCouponWrapper isDiscount={!!promoCode}>
            <IconWrapper>
              <GreenTag width={20} height={20} />
            </IconWrapper>
            <TextCoupon>{`${promoCode}:`}</TextCoupon>
            <TextCoupon2>{`-$${discountValue}`}</TextCoupon2>
          </DiscountsCouponWrapper>
        )}

        {isFirstMonthDiscount && (
          <DiscountsCouponWrapper isDiscount={!!promoCode}>
            <IconWrapper>
              <GreenTag width={20} height={20} />
            </IconWrapper>
            <TextCoupon>{`${firstMonthDiscountPromoCode}:`}</TextCoupon>
            <TextCoupon2>{`-$${firstMonthDiscountAmount}`}</TextCoupon2>
          </DiscountsCouponWrapper>
        )}

        <OrderCouponWrapper>
          <TextCouponTitleTotal>{'Order Total:'}</TextCouponTitleTotal>
          <TextCouponTotal>{`$${
            isFirstMonthDiscount ? totalAmountWithFirstMonthDiscount : formatNumber(totalAmount)
          }`}</TextCouponTotal>
        </OrderCouponWrapper>

        <CouponWrapper>
          <TextCoupon>Initial Payment:</TextCoupon>
          <TextCoupon2>{`$${initialAmount}`}</TextCoupon2>
        </CouponWrapper>

        <CouponWrapper>
          <TextCoupon>+11 Payments Of:</TextCoupon>
          <TextCoupon2>{`$${perMonth}`}</TextCoupon2>
        </CouponWrapper>
      </SuccessCouponContainer>
      {isFirstDiscount && !isLoadingPromocode && (
        <StyledCheckbox
          variant={CheckboxVariant.white}
          label={discountCheckboxLabel}
          onClick={toggleIsFirstMonthDiscount}
          checked={isFirstMonthDiscount}
        />
      )}
    </div>
  );
};

export default SuccessCoupon;

const SuccessCouponContainer = styled.div`
  width: 300px;
  display: grid;
  gap: 7px;
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 12px;
  background-color: ${({ theme: { colors } }) => colors.neutrals[11]};
`;

const TextCoupon = styled.span`
  ${({ theme: { typography } }) => css`
    ${typography.body_basic_medium_14}
  `};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const TextCouponTitleTotal = styled.span`
  ${({ theme: { typography } }) => css`
    ${typography.body_basic_bold_14}
  `};
`;

const TextCoupon2 = styled.span`
  margin-left: auto;
  ${({ theme: { typography } }) => css`
    ${typography.body_basic_medium_14}
  `};
`;

const TextCouponTotal = styled.span`
  margin-left: auto;
  ${({ theme: { typography } }) => css`
    ${typography.body_basic_bold_14}
  `};
`;

const StyledCheckbox = styled(Checkbox)`
  ${({ theme: { typography, colors } }) => css`
    margin-top: 20px;
    margin-bottom: 20px;

    ${typography.body_basic_bold_14}

    & > span {
      color: ${colors.neutrals[11]};
    }
  `}
`;

const CouponWrapper = styled.div`
  display: flex;
  gap: 40px;
`;

const DiscountsCouponWrapper = styled.div<{ isDiscount: boolean }>`
  display: flex;
  color: ${({ isDiscount }) => (isDiscount ? '#55b47d' : '#9B9B9B')};
`;

const OrderCouponWrapper = styled.div`
  display: flex;
  gap: 40px;
  ${({ theme: { typography } }) => css`
    ${typography.body_basic_bold_14}
  `};
`;
