import { FC, memo } from 'react';
import { SVGIconProps } from 'types';

const SvgGreenTag: FC<SVGIconProps> = ({ size = 132, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_17234_11701)">
        <path
          d="M2.99201 0.952011C2.23201 1.13601 1.43201 1.80801 1.09601 2.54401C0.920009 2.91201 0.920009 2.99201 0.896009 7.32801L0.872009 11.736L1.47201 12.296C2.03201 12.816 2.07201 12.872 2.04001 13.128C2.01601 13.28 1.96801 13.888 1.92001 14.48C1.88001 15.072 1.75201 16.712 1.64001 18.128C1.45601 20.456 1.44801 20.728 1.56001 21.088C1.70401 21.576 2.05601 22.016 2.53601 22.288C2.86401 22.488 3.03201 22.512 4.54401 22.64C5.92801 22.752 6.26401 22.808 6.60001 22.96C7.22401 23.256 7.74401 23.208 9.79201 22.656L11.552 22.184L11.944 22.432C12.296 22.648 12.432 22.68 13.008 22.712C14.16 22.76 14 22.88 18.544 18.424C20.728 16.28 22.6 14.408 22.704 14.28C23.024 13.864 23.144 13.504 23.152 12.92C23.16 12.056 22.944 11.688 21.704 10.48C20.536 9.34401 17.472 6.33601 14.192 3.09601L11.936 0.880011L7.59201 0.888012C5.20001 0.896011 3.12801 0.920012 2.99201 0.952011ZM16.656 7.16001C20.704 11.136 21.928 12.392 22.008 12.616C22.208 13.232 22.248 13.176 17.856 17.504C13.416 21.872 13.488 21.816 12.832 21.6C12.528 21.496 11.672 20.672 3.22401 12.368L2.00001 11.168V7.16001C2.00001 3.44801 2.01601 3.13601 2.15201 2.84001C2.31201 2.48801 2.48001 2.31201 2.84001 2.12801C3.04801 2.01601 3.62401 2.00001 7.24001 2.00001H11.4L16.656 7.16001ZM4.37601 17.824C4.92801 19.856 5.36801 21.536 5.35201 21.552C5.28801 21.608 3.39201 21.432 3.16801 21.344C2.85601 21.216 2.56801 20.896 2.51201 20.592C2.47201 20.384 2.77601 15.824 2.92801 14.248L2.98401 13.744L3.16801 13.936C3.32801 14.088 3.56001 14.848 4.37601 17.824ZM8.46401 19.144C9.66401 20.328 10.632 21.312 10.616 21.336C10.52 21.432 7.82401 22.096 7.51201 22.104C7.11201 22.112 6.68001 21.896 6.49601 21.6C6.44001 21.512 6.05601 20.184 5.63201 18.656C5.21601 17.128 4.85601 15.808 4.83201 15.72C4.77601 15.536 5.39201 16.12 8.46401 19.144Z"
          fill="#55B47D"
        />
        <path
          d="M6.24799 4.48797C5.45599 4.63997 4.83199 5.23997 4.59199 6.06397C4.29599 7.07197 4.86399 8.17597 5.89599 8.60797C6.28799 8.77597 7.14399 8.78397 7.53599 8.61597C7.94399 8.43997 8.55199 7.86397 8.72799 7.47997C8.94399 7.01597 8.92799 6.15997 8.71199 5.69597C8.27199 4.79197 7.24799 4.28797 6.24799 4.48797ZM7.08799 5.80797C7.52799 6.01597 7.72799 6.56797 7.51199 6.98397C7.35199 7.29597 7.10399 7.43997 6.69599 7.43997C6.37599 7.43997 6.27199 7.39997 6.07199 7.20797C5.64799 6.77597 5.79199 6.03997 6.35199 5.79197C6.67999 5.65597 6.75999 5.65597 7.08799 5.80797Z"
          fill="#55B47D"
        />
      </g>
      <defs>
        <clipPath id="clip0_17234_11701">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Memo = memo(SvgGreenTag);
export default Memo;
